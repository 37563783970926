define("discourse/plugins/discourse-subscription/discourse/subscription-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Links the path `/subscription` to a route named `subscription`. Named like this, a
  // route with the same name needs to be created in the `routes` directory.
  //
  var _default = _exports.default = {
    resource: 'user',
    map() {
      this.route('subscription', {
        resetNamespace: true
      });
    }
  };
});