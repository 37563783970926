define("discourse/plugins/discourse-subscription/discourse/templates/connectors/user-main-nav/subscription-tab", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="user-primary-navigation">
    {{#mobile-nav class="main-nav" desktopClass="nav nav-pills user-nav" currentPath=currentPath}}
      <li>{{#link-to "subscription"}}{{d-icon "medal"}}{{i18n "subscription.tab"}}{{/link-to}}</li>
    {{/mobile-nav}}
  </section>
  
  
  */
  {
    "id": "MjOUswbr",
    "block": "[[[10,\"section\"],[14,0,\"user-primary-navigation\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"class\",\"desktopClass\",\"currentPath\"],[\"main-nav\",\"nav nav-pills user-nav\",[30,0,[\"currentPath\"]]]],[[\"default\"],[[[[1,\"    \"],[10,\"li\"],[12],[6,[39,1],[\"subscription\"],null,[[\"default\"],[[[[1,[28,[35,2],[\"medal\"],null]],[1,[28,[35,3],[\"subscription.tab\"],null]]],[]]]]],[13],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\\n\"],[1,[28,[32,0],[\"[[\\\"The `currentPath` property path was used in the `discourse/plugins/discourse-subscription/discourse/templates/connectors/user-main-nav/subscription-tab.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentPath}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"mobile-nav\",\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-subscription/discourse/templates/connectors/user-main-nav/subscription-tab.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});