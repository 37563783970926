define("discourse/plugins/discourse-sso-preferences/discourse/initializers/sso-preferences", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeSsoPreferences(api) {
    api.decoratePluginOutlet('user-preferences-account', (elem, args) => {
      var ssoPreferences = elem.querySelector('#sso-preferences');
      if (ssoPreferences) {
        var before = document.querySelector('.pref-name');
        if (before == null) {
          before = document.querySelector('.pref-email');
        }
        var container = elem.parentElement.parentElement;
        elem.remove();
        if (before) {
          container.insertBefore(ssoPreferences, before);
        } else {
          container.prepend(ssoPreferences);
        }
        ssoPreferences.hidden = false;
      }
    });
  }
  var _default = _exports.default = {
    name: "sso-preferences",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeSsoPreferences);
    }
  };
});