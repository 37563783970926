define("discourse/plugins/discourse-subscription/initializers/subscription", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    const user = api.getCurrentUser();
    if (user) {
      const {
        username
      } = user;
      if (!username) return;
      api.addQuickAccessProfileItem({
        icon: "medal",
        href: `/u/${username}/subscription`,
        content: I18n.t('subscription.menu.activate_patron'),
        className: "subscription"
      });
    }
  }
  var _default = _exports.default = {
    name: "discourse-subscription",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.33.0", initializePlugin);
    }
  };
});