define("discourse/plugins/discourse-subscription/discourse/routes/subscription", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    renderTemplate() {
      // Render `../templates/subscription.hbs`
      this.render('subscription');
    },
    model() {
      return this.modelFor("user");
    }
  });
});