define("discourse/plugins/discourse-subscription/discourse/models/subscription", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    createProperties() {
      return this.getProperties('content', 'username', 'expired_at');
    }
  });
});