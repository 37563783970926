define("discourse/plugins/discourse-subscription/discourse/controllers/subscription", ["exports", "@ember/controller", "discourse/models/user"], function (_exports, _controller, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    message: null,
    actions: {
      addSubscription(content) {
        const {
          username
        } = _user.default.current();
        if (!content || !username) {
          return;
        }
        content = content.trim().toLowerCase();

        // Save subscription and username to plugin_store_rows table
        const subscriptionRecord = this.store.createRecord('subscription', {
          content,
          username
        });
        subscriptionRecord.save().then(res => {
          const {
            responseJson
          } = res;
          const {
            trial,
            duplicated,
            success
          } = responseJson;
          if (trial) {
            this.set('message', I18n.t('subscription.msg.subscription_trial'));
            return;
          }
          if (duplicated) {
            this.set('message', I18n.t('subscription.msg.link_duplicated'));
          } else if (success) {
            this.set('message', I18n.t('subscription.msg.link_success'));
          } else {
            this.set('message', I18n.t('subscription.msg.link_failure'));
          }
          return;
        }).catch(() => {
          this.set('message', I18n.t('subscription.msg.link_failure'));
        });
      }
    }
  });
});